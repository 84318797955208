import { APIConfigTypes } from "./interfaces";

export enum APIMethods {
  POST = "POST",
  GET = "GET",
  UPDATE = "UPDATE",
  DELETE = "DELETE",
  PUT = "PUT",
  PATCH = "PATCH",
}
// export const BASE_URL_K_BOMBAY = "http://3.111.245.9:8080/rigeleduapp";
// export const BASE_URL_K_BOMBAY = "https://3.111.245.9:443/rigeleduapp";
export const BASE_URL_K_BOMBAY = "https://rigelapi.com/rigeleduapp";
export const K_BOMBAY_APPNAME = "guessgame";
export const API_CONFIGS: APIConfigTypes = {
  SIGN_UP: {
    URL: "/api/auth/signup",
    METHOD: APIMethods.POST,
  },
  LOGIN: {
    URL: "/api/auth/signin",
    METHOD: APIMethods.POST,
  },
  CREATE_GAME: {
    URL: "/guessgametype/create",
    METHOD: APIMethods.POST,
  },
  UPDATE_GAME: {
    URL: "/guessgametype/update",
    METHOD: APIMethods.POST,
  },
  FETCH_CURRENT_DAY_GAMES: {
    URL: "/auths/guessgametype/fetchallcurrent",
    METHOD: APIMethods.GET,
  },
  FETCH_GAMES_HISTORY: {
    URL: "/auths/guessgametype/fetchallhistory",
    METHOD: APIMethods.GET,
  },
  TRIGGER_SCHEDULE_TIME: {
    URL: "/guessgametype/copyatscheduledtime",
    METHOD: APIMethods.POST,
  },
  CREATE_GUESS_TABLE: {
    URL: "/guesstable/create",
    METHOD: APIMethods.POST,
  },
  UPDATE_GUESS_TABLE: {
    URL: "/guesstable/update",
    METHOD: APIMethods.POST,
  },
  REMOVE_GUESS_TABLE: {
    URL: "/guesstable/removeByGuessTable",
    METHOD: APIMethods.POST,
  },
  FETCH_GUESS_TABLE: {
    URL: "/auths/guesstable/fetchall",
    METHOD: APIMethods.GET,
  },
  FETCH_GUESS_TABLE_BY_RECORD_DATE: {
    URL: "/auths/guesstable/fetchallbyrecorddate",
    METHOD: APIMethods.GET,
  },
  CREATE_FATAFAT: {
    URL: "/falafat/create",
    METHOD: APIMethods.POST,
  },
  UPDATE_FATAFAT: {
    URL: "/falafat/update",
    METHOD: APIMethods.POST,
  },
  DELETE_FATAFAT: {
    URL: "/falafat/removeByFatafat",
    METHOD: APIMethods.POST,
  },
  FETCH_FATAFAT: {
    URL: "/auths/falafat/fetchall",
    METHOD: APIMethods.GET,
  },
  FETCH_GAMES_TIMETABLE: {
    URL: "/auths/gametimetable/fetchall",
    METHOD: APIMethods.GET,
  },
  SAVE_GUESS_DAY:{
    URL: "/guessday/createorupdate",
    METHOD: APIMethods.POST
  },
  FETCH_GUESS_DAY:{
    URL:"/auths/guessday/fetchbyfromdateandtodate",
    METHOD:APIMethods.POST
  },
  SAVE_GAME_PATTI:{
    URL:"/guesspatti/createorupdate",
    METHOD:APIMethods.POST
  },
  FETCH_GAME_PATTI:{
    URL:"/auths/guesspatti/fetchbyfromdateandtodate",
    METHOD:APIMethods.POST
  }
};
