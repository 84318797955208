import React, { useCallback, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  FIREBASE_COLLECTION_DOC_KEY,
  FIREBASE_COLLECTION_KEY,
  ROUTE_PATHS,
} from "../../../constants";
import deleteIcon from "../images/delete_red.png";
import editIcon from "../images/edit.png";
import { updateCollection } from "../../../services/firebase";
import { getCurrentDayGames, onTimeChange, sortListByHHMMTime } from "../../../util";
import Button from "react-bootstrap/esm/Button";

const MatkaResultsCard = ({
  isAdminLoggedIn,
  data,
  gamesList,
  onAddClick,
  onSaveClick,
}) => {
  const navigate = useNavigate();

  const onEditGame = useCallback(
    (index) => {
      navigate(`${ROUTE_PATHS.ADD_GAME}/${index}`);
    },
    [navigate]
  );

  const onDeleteGame = useCallback(
    (index) => {
      let gamesTypeList = [...data.gameTypes];

      gamesTypeList.splice(index, 1);

      let updatedData = {
        ...data,
        gameTypes: gamesTypeList,
      };
      console.log("onDeleteGame data", updatedData);
      updateCollection(
        FIREBASE_COLLECTION_KEY,
        FIREBASE_COLLECTION_DOC_KEY,
        updatedData
      )
        .then((response) => {
          console.info("firebase updated successfully!", response);
          window.location.reload();
        })
        .catch((e) => {
          console.error("firebase updated failed!", e);
        });
    },
    [data]
  );

  // const todaysGames = getCurrentDayGames(sortListByHHMMTime(gamesList)) ?? getCurrentDayGames(data.gameTypes);
  const todaysGames = gamesList ?? getCurrentDayGames(data.gameTypes);
  return (
    <div className="container-fluid">
      <div className="container">
        <div className="matka-results" style={{ paddingBottom: 0 }}>
          <h2>Matka Results</h2>
          {isAdminLoggedIn && (
            <>
              <div className="d-flex align-items-center justify-content-center">
                <Link
                  to={ROUTE_PATHS.ADD_GAME}
                  title="Add New Game"
                  className="add-fatafat-btn"
                >
                +
                </Link>
                {/* <Button variant="primary" onClick={onAddClick}>
                  Add Game
                </Button> */}
                {/* <Button variant="primary" onClick={onSaveClick}>
                  Save Game
                </Button> */}
              </div>
              {/* <div id="container"></div> */}
            </>
          )}
        </div>
        {todaysGames.length === 0 && (
          <div className="shadow_card">
            <h1>No games created yet!</h1>
          </div>
        )}
        {todaysGames.map((item, index) => {
          return (
            <div
              className="col-lg-4 col-md-4 col-sm-6 col-xs-12"
              key={`gametypes-${index}`}
            >
              <div className="single-result">
                <h2>
                  <span className="left-time">{onTimeChange(item.gamesTimeTableUI.startTime)}</span>
                  {item.gamesTimeTableUI.name} <span>{onTimeChange(item.gamesTimeTableUI.endTime)}</span>
                </h2>
                <ul>
                  <li>
                    <p>{item.lhsValue}</p>
                  </li>
                  <li>
                    <p className="bold-sec">{item.winnerValue}</p>
                  </li>
                  <li>
                    <p>{item.rhsValue}</p>
                  </li>
                </ul>
                {isAdminLoggedIn && (
                  <div className="">
                    {/* <img
                      className="img-btn"
                      src={deleteIcon}
                      title={`delete game`}
                      alt={`delete-game`}
                      onClick={() => onDeleteGame(index)}
                    /> */}
                    <img
                      className="img-btn"
                      src={editIcon}
                      title={`edit game`}
                      alt={`edit-game`}
                      onClick={() => onEditGame(index)}
                    />
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MatkaResultsCard;
