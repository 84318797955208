import React from "react";

const TopBanner = ({ isAdminLoggedIn, data, adminUserName }) => {
  return (
    <div
      className="container-fluid"
      // style={{ backgroundColor: "rgba(1,67,154,0.9)" }}
    >
      <div className="container round_border_card mt-5">
        <div  className="row">
          <div className="phone-num">
            <h2>
              <b style={{color:'white'}} >
                {!isAdminLoggedIn ? data.title : `Welcome ${adminUserName}`}
              </b>
            </h2>
            <h2 style={{color:'white'}}>
              <b>{data.hashTag}</b>
            </h2>
            <a href={`tel:${data.contactNumber}`}>
              {data.callUs} <span>{data.contactNumber}</span>
            </a>
          </div>
          <hr />
          <p style={{ color: "white", textAlign:'left', marginLeft:'10px', marginRight:'10px' }}>{data.description}</p>
        </div>
      </div>
    </div>
  );
};
export default TopBanner;
