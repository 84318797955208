import React from "react";
import server_down from './images/serverdown.jpeg'
import "./css/serverdown.css";

const ServerDownScreen = () => {
  return (
    <div className="page-container">
      <div className="content">
        <div className="image-placeholder">
          <img src={server_down} alt="Server Down" />
          <label className="image-label">Please contact the maintenance team</label>
        </div>
      </div>
    </div>
  );
};

export default ServerDownScreen;