import React from "react";
import { useParams } from "react-router-dom";
import { getOldDataByName, getPanelJodiChartDetailData } from "../../util";
import "./css/style.min.css";
import "./css/matkaplay.css";
import "../common.css";
import ScrollToTop from "../../services/scrollToTop";

const PanelChartDetailScreen = ({ data }) => {
  const params = useParams();
  const gameNameVal = params.gameName;
  const oldData = getOldDataByName(gameNameVal);

  return (
    <>
      <div className="round_border_card">
        <h2 className="white-header">{gameNameVal} </h2>
        <div className="panel-detail">
          <table>
            <tbody>
              <tr>
                <th>Date</th>
                <th>Mon</th>
                <th>Tue</th>
                <th>Wed</th>
                <th>Thu</th>
                <th>Fri</th>
                <th>Sat</th>
                <th>Sun</th>
              </tr>
              {oldData?.data?.map((item, index) => {
                const splitByToDate = item.date.split("to");
                return (
                  <tr key={`tabletable-old-${index}`}>
                    <td>
                      {splitByToDate[0]} <br /> to <br />
                      {splitByToDate[1]}
                    </td>
                    <td>
                      <div className="panel-detail-td">
                        <span>{item.mo.trim().split(' ')[0]} <br /> {item.mo.trim().split(' ')[1]} <br /> {item.mo.trim().split(' ')[2]}</span>
                      </div>
                    </td>
                    <td>
                      <div className="panel-detail-td">
                      <span>{item.tu.trim().split(' ')[0]} <br /> {item.tu.trim().split(' ')[1]} <br /> {item.tu.trim().split(' ')[2]}</span>
                      </div>
                    </td>
                    <td>
                      <div className="panel-detail-td">
                      <span>{item.we.trim().split(' ')[0]} <br /> {item.we.trim().split(' ')[1]} <br /> {item.we.trim().split(' ')[2]}</span>
                      </div>
                    </td>
                    <td>
                      <div className="panel-detail-td">
                      <span>{item.th.trim().split(' ')[0]} <br /> {item.th.trim().split(' ')[1]} <br /> {item.th.trim().split(' ')[2]}</span>
                      </div>
                    </td>
                    <td>
                      <div className="panel-detail-td">
                      <span>{item.fr.trim().split(' ')[0]} <br /> {item.fr.trim().split(' ')[1]} <br /> {item.fr.trim().split(' ')[2]}</span>
                      </div>
                    </td>
                    <td>
                      <div className="panel-detail-td">
                      <span>{item.sa.trim().split(' ')[0]} <br /> {item.sa.trim().split(' ')[1]} <br /> {item.sa.trim().split(' ')[2]}</span>
                      </div>
                    </td>
                    <td>
                      <div className="panel-detail-td">
                      <span>{item.su.trim().split(' ')[0]} <br /> {item.su.trim().split(' ')[1]} <br /> {item.su.trim().split(' ')[2]}</span>
                      </div>
                    </td>
                  </tr>
                );
              })}
              {getPanelJodiChartDetailData(data, gameNameVal)?.map((item, index) => {
                const splitByTo = item.weekIntervalTime.split("to");
                return (
                  <tr key={`table-new-${item.dayWiseData}`}>
                    <td>
                      {splitByTo[0]} <br /> to <br />
                      {splitByTo[1]}
                    </td>
                    <>
                      {item.dayWiseData.map((day, innerIndex) => {
                        return (
                          <td key={`table-dayWiseData-${day.lhsValue}_${innerIndex}`}>
                            <div className="panel-detail-td">
                              <span>{day.lhsValue}</span><br />
                              <span>{day.winnerValue}</span><br />
                              <span>{day.rhsValue}</span>
                            </div>
                          </td>
                        );
                      })}
                    </>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <ScrollToTop />
    </>
  );
};

export default PanelChartDetailScreen;
