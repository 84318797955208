import React from "react";
import { Link } from "react-router-dom";
import { ChartsType, ROUTE_PATHS } from "../../../constants";
import { getPanelJodiChartData } from "../../../util";

const JodiPanelChartsCard = ({ title, data, type = ChartsType.PANEL }) => {
  const jodiPanelData = getPanelJodiChartData(data);
  return (
    <div className="container-fluid" >
      <div className="container">
        <div className="row gold-jchat" >
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" >
            <div className="g-jchat" >
              <h2>{title}</h2>
              {Object.keys(jodiPanelData).map((item, index) => {
                const titleExt =
                  type === ChartsType.PANEL ? "PanelChart" : "Jodi";
                return (
                  <Link
                    key={`${item}-${index}`}
                    to={
                      type === ChartsType.PANEL
                        ? `${ROUTE_PATHS.PANEL_CHARTS_DETAIL}/${item}`
                        : `${ROUTE_PATHS.JDOI_CHARTS_DETAIL}/${item}`
                    }
                    title={`${titleExt}-${item}`}
                    // className="addGameBtn"
                  >
                    {item}
                    <i className="fa fa-paper-plane" aria-hidden="true"></i>
                  </Link>
                  // <a href={item.hrefVal} key={`jodi-${index}`}>
                  //   {item.name}
                  //   <span>
                  //     <i className="fa fa-paper-plane" aria-hidden="true"></i>
                  //   </span>
                  // </a>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JodiPanelChartsCard;
