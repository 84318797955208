import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "../../../constants";
import { getCurrentDayGames, getStartandEndofWeek } from "../../../util";
import editIcon from "../images/edit.png";
import deleteIcon from "../images/delete_red.png";
import { APIService } from "../../../App";
import { API_CONFIGS } from "../../../services/fetchAPI/constants";

const GuessingTableCard = ({
  isAdminLoggedIn,
  data = [],
  newGuessTableData,
  pattiTableData,
}) => {
  let content;
  const navigate = useNavigate();
  const { startWeekDate, endWeekDate } = getStartandEndofWeek();
  const [tableData, setTableData] = useState({
    Mon: newGuessTableData?.mondayvalue ?? '',
    Tue: newGuessTableData?.tuesdayvalue ?? '',
    Wed: newGuessTableData?.wednesdayvalue ?? '',
    Thu: newGuessTableData?.thursdayvalue ?? '',
    Fri: newGuessTableData?.fridayvalue ?? '',
    Sat: newGuessTableData?.saturdayvalue ?? '',
    Sun: newGuessTableData?.sundayvalue ?? '',
  });
  const [pattiData, setPattiData] = useState({
    0: pattiTableData?.zerovalue ?? '',
    1: pattiTableData?.onevalue ?? '',
    2: pattiTableData?.twovalue ?? '',
    3: pattiTableData?.threevalue ?? '',
    4: pattiTableData?.fourvalue ?? '',
    5: pattiTableData?.fivevalue ?? '',
    6: pattiTableData?.sixvalue ?? '',
    7: pattiTableData?.sevenvalue ?? '',
    8: pattiTableData?.eightvalue ?? '',
    9: pattiTableData.ninevalue ?? '',
  });

  const saveGuessDataToDB = () => {
    const guessFormattedTableData = {
      mondayvalue: tableData.Mon,
      tuesdayvalue: tableData.Tue,
      wednesdayvalue: tableData.Wed,
      thursdayvalue: tableData.Thu,
      fridayvalue: tableData.Fri,
      saturdayvalue: tableData.Sat,
      sundayvalue: tableData.Sun,
      fromdate: startWeekDate,
      todate: endWeekDate,
    };
    APIService.post(API_CONFIGS.SAVE_GUESS_DAY.URL, guessFormattedTableData)
      .then((response) => {
        alert("Guess Table Data Saved");
        console.log("Guess Table response", response);
      })
      .catch((err) => {
        console.log("Error occured while entering the Guess Table value", err);
      });
  };

  const savePattiTableData = () => {
    const pattiFormattedTableData = {
      zerovalue: pattiData[0],
      onevalue: pattiData[1],
      twovalue: pattiData[2],
      threevalue: pattiData[3],
      fourvalue: pattiData[4],
      fivevalue: pattiData[5],
      sixvalue: pattiData[6],
      sevenvalue: pattiData[7],
      eightvalue: pattiData[8],
      ninevalue: pattiData[9],
      fromdate: startWeekDate,
      todate: endWeekDate,
    };
    APIService.post(API_CONFIGS.SAVE_GAME_PATTI.URL, pattiFormattedTableData)
      .then((res) => {
        alert("Patti Table data saved");
        console.log("Patti table response", res);
      })
      .catch((err) => {
        console.log("Error occured while entering the Patti Table value", err);
      });
  };



  const handlePattiEdit = (num) => {
    const newVal = prompt(
      `Enter the value to edit for day ${num}:`,
      pattiData[num]
    );

    if (newVal != null) {
      const isValid = /^\d*$/.test(newVal);
      if (!isValid) {
        alert("Please enter numbers only or leave it empty.");
        return;
      }

      const formattedVal = newVal ? newVal.match(/.{1,3}/g).join(" ") : "";
      setPattiData((prevVal) => ({
        ...prevVal,
        [num]: formattedVal,
      }));
    }
  };

  const handleGuessEdit = (day) => {
    const newVal = prompt(
      `Enter the value to edit for day ${day}:`,
      tableData[day]
    );

    if (newVal != null) {
      const isValid = /^\d*$/.test(newVal);
      if (!isValid) {
        alert("Please enter numbers only or leave it empty.");
        return;
      }
      const formattedVal = newVal ? newVal.split("").join(" ") : "";
      setTableData((prevVal) => ({
        ...prevVal,
        [day]: formattedVal,
      }));
    }
  };

  if (Object.keys(newGuessTableData).length === 0 && Object.keys(pattiTableData).length !== 0) {
    content = (
      <div>
        <div className="shadow_card">
          <h1>No results available!</h1>
        </div>
        <div
          className="shadow_card"
          style={{ backgroundColor: "rgb(8,101,252)" }}
        >
          <div style={{ textAlign: "center" }}>
            <p style={{ color: "white" }}>
              Weekly Panel Or Patti Chart From <b>{startWeekDate}</b> To{" "}
              <b>{endWeekDate}</b> For Satyam day, K Bombay, Satyam night,
              Main Bazer, Tata Time Bazaar, Kalyan, Bhoothnath
            </p>
          </div>
          <hr
            style={{
              display: "block",
              border: "1px solid black",
              height: "0.5px",
              width: "100%",
              backgroundColor: "black",
            }}
          />
          <div
            style={{
              backgroundColor: "white",
              display: "flex",
              justifyContent: "center",
              flexDirection: "row",
            }}
          >
            <div className="patti_Table">
              {Object.keys(pattiData).map((num) => (
                <div
                  key={num}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    margin: "4px 0",
                  }}
                >
                  <p className="pattiTable_num">{num} =&gt;&nbsp;&nbsp;</p>
                  <p className="pattiTable_text">
                    {pattiData[num] ? pattiData[num] : "*** *** ***"}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  } else if (Object.keys(newGuessTableData).length !== 0 && Object.keys(pattiTableData).length === 0) {
    content = (
      <div>
        <div>
          <div className="shadow_card">
            {/* <h1>No results available!</h1> */}
            <div style={{ marginLeft: "auto", marginRight: "auto" }}>
              <h3>Weekly Number Open Or Close From</h3>
              <p>
                <b>{startWeekDate}</b> To <b>{endWeekDate}</b> For Satyam
                day,K Bombay,Satyam night,Main Bazer, Tata Time Bazaar, Kalyan, Bhoothnath
              </p>
            </div>
            <hr
              style={{
                display: "block",
                border: "1px solid black",
                height: "2px",
                width: "100%",
                backgroundColor: "black",
              }}
            />
            <table className="firstCard_table">
              <tbody>
                {Object.keys(tableData).map((day) => (
                  <tr key={day}>
                    <td
                      style={{
                        border: "3px solid black",
                        width: "50%",
                        verticalAlign: "middle",
                        fontSize: "20px",
                        fontWeight: "bold",
                      }}
                    >
                      {day}
                    </td>
                    <td
                      style={{
                        border: "3px solid black",
                        verticalAlign: "middle",
                        fontSize: "20px",
                        fontWeight: "bold",
                      }}
                    >
                      <div
                        style={{
                          textAlign: "center",
                          fontSize: "20px",
                          fontWeight: "bold",
                        }}
                      >
                        <p style={{ margin: 0 }}>
                          {/* {resByDB && resByDB.model ? resByDB.model[day] : "Loading..."} */}
                          {tableData[day] ? tableData[day] : "* * * *"}
                        </p>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div
              style={{
                paddingTop: "30px",
                paddingBottom: "30px",
                backgroundColor: "rgb(8,102,255)",
                color: "white",
                textAlign: "center",
              }}
            >
              <b>OPEN TO CLOSE FREE GAME ZONE</b>
            </div>
          </div>
          <div className="shadow_card">
            <h1>No results available!</h1>
          </div>
        </div>
      </div>
    );
  }
  else if(Object.keys(newGuessTableData).length === 0 && Object.keys(pattiTableData).length === 0){
   content=( <div>
      <div className="shadow_card" ><h1>No results available!</h1></div>
    </div>)
  }
  else {
    content = (
      <div>
        <div className="shadow_card">
            {/* <h1>No results available!</h1> */}
            <div style={{ marginLeft: "auto", marginRight: "auto" , textAlign:"center"}}>
              <h3>Weekly Number Open Or Close From</h3>
              <p>
                <b>{startWeekDate}</b> To <b>{endWeekDate}</b> For Satyam
                day,K Bombay,Satyam night,Main Bazer, Tata Time Bazaar, Kalyan, Bhoothnath
              </p>
            </div>
            <hr
              style={{
                display: "block",
                border: "1px solid black",
                height: "2px",
                width: "100%",
                backgroundColor: "black",
              }}
            />
            <table className="firstCard_table">
              <tbody>
                {Object.keys(tableData).map((day) => (
                  <tr key={day}>
                    <td
                      style={{
                        border: "3px solid black",
                        width: "50%",
                        verticalAlign: "middle",
                        fontSize: "20px",
                        fontWeight: "bold",
                      }}
                    >
                      {day}
                    </td>
                    <td
                      style={{
                        border: "3px solid black",
                        verticalAlign: "middle",
                        fontSize: "20px",
                        fontWeight: "bold",
                      }}
                    >
                      <div
                        style={{
                          textAlign: "center",
                          fontSize: "20px",
                          fontWeight: "bold",
                        }}
                      >
                        <p style={{ margin: 0 }}>
                          {/* {resByDB && resByDB.model ? resByDB.model[day] : "Loading..."} */}
                          {tableData[day] ? tableData[day] : "* * * *"}
                        </p>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div
              style={{
                paddingTop: "30px",
                paddingBottom: "30px",
                backgroundColor: "rgb(8,102,255)",
                color: "white",
                textAlign: "center",
              }}
            >
              <b>OPEN TO CLOSE FREE GAME ZONE</b>
            </div>
          </div>
        <div
          className="shadow_card"
          style={{ backgroundColor: "rgb(8,101,252)" }}
        >
          <div style={{ textAlign: "center" }}>
            <p style={{ color: "white",marginLeft:"auto",marginRight:"auto" }}>
              Weekly Panel Or Patti Chart From <b>{startWeekDate}</b> To{" "}
              <b>{endWeekDate}</b> For Satyam day,K Bombay,Satyam night,Main Bazer, Tata Time Bazaar, Kalyan, Bhoothnath

            </p>
          </div>
          <hr
            style={{
              display: "block",
              border: "1px solid black",
              height: "0.5px",
              width: "100%",
              backgroundColor: "black",
            }}
          />
          <div
            style={{
              backgroundColor: "white",
              display: "flex",
              justifyContent: "center",
              flexDirection: "row",
            }}
          >
            <div className="patti_Table">
              {Object.keys(pattiData).map((num) => (
                <div
                  key={num}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    margin: "4px 0",
                  }}
                >
                  <p className="pattiTable_num">{num} =&gt;&nbsp;&nbsp; </p>{" "}
                  <p className="pattiTable_text">
                    {pattiData[num] ? pattiData[num] : "*** *** ***"}
                  </p>{" "}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  const onEditGame = useCallback(
    (guessGameId) => {
      navigate(`${ROUTE_PATHS.ADD_EDIT_GUESSING_TABLE_RESULT}/${guessGameId}`);
    },
    [navigate]
  );

  const onDeleteGuessTableAPICall = (body) => {
    APIService.post(API_CONFIGS.REMOVE_GUESS_TABLE.URL, body)
      .then((response) => {
        // setData([...data, response.data]);
        console.log(
          "deleteGuessTableAPICall API_CONFIGS.REMOVE_GUESS_TABLE.URL",
          response
        );
        if (response?.data?.status) {
          alert(`Success: ${response?.data?.sucessMsg}`);
          window.location.reload();
        } else if (
          response?.data?.errMessages &&
          response?.data?.errMessages.length > 0
        ) {
          const errorMSg = response.data.errMessages[0].errMessage;
          alert(errorMSg);
        }
      })
      .catch((error) => {
        console.error("Error deleteGuessTableAPICall:", error);
      });
  };

  const onDelete = (body) => {
    if (window.confirm("Are you sure you want to delete this game?")) {
      // Save it!
      onDeleteGuessTableAPICall(body);
    } else {
      // Do nothing!
      console.log("Thing was not deleted to the database.");
    }
  };

  return (
    <div className="container-fluid">
      <div className="container">
        <div className="matka-results" style={{ paddingBottom: 0 }}>
          <h2>Guessing Table</h2>
          <div className="test">
            {isAdminLoggedIn && (
              <div className="d-flex align-items-center test">
                {/* <img
                  className="img-btn"
                  src={editIcon}
                  title="Edit game"
                  alt="edit-game"
                  onClick={onEditGame}
                /> */}
                <Link
                  to={ROUTE_PATHS.ADD_EDIT_GUESSING_TABLE_RESULT}
                  title="Add Fatafat Result"
                  className="add-fatafat-btn"
                >
                  <b>+</b>
                </Link>
              </div>
            )}
            {/* <Link
              to={ROUTE_PATHS.FATAFAT_RESULT_DETAIL}
              title="Fatafat chart"
              className="shantiMatkaChartBtn"
            >
              Chart
            </Link> */}
          </div>
        </div>
        {!isAdminLoggedIn ? (
          // <div className="shadow_card">
          //   <h1>No results available!</h1>
          // </div>

          <div>
            {content ? content : ""}
           
          </div>
        ) : (
          // <div>

          //   <div className="shadow_card">
          //     {/* <h1>No results available!</h1> */}
          //     <div style={{ marginLeft: "auto", marginRight: "auto" }}>
          //       <h3>Weekly Number Open Or Close From</h3>
          //       <p>
          //         <b>{startWeekDate}</b> To <b>{endWeekDate}</b> For Satyam
          //         day,Kolkata Bombay,Satyam night,Main Bazer
          //       </p>
          //     </div>
          //     <hr
          //       style={{
          //         display: "block",
          //         border: "1px solid black",
          //         height: "2px",
          //         width: "100%",
          //         backgroundColor: "black",
          //       }}
          //     />
          //     <table className="firstCard_table">
          //       <tbody>
          //         {Object.keys(tableData).map((day) => (
          //           <tr key={day}>
          //             <td
          //               style={{
          //                 border: "3px solid black",
          //                 width: "50%",
          //                 verticalAlign: "middle",
          //                 fontSize: "20px",
          //                 fontWeight: "bold",
          //               }}
          //             >
          //               {day}
          //             </td>
          //             <td
          //               style={{
          //                 border: "3px solid black",
          //                 verticalAlign: "middle",
          //                 fontSize: "20px",
          //                 fontWeight: "bold",
          //               }}
          //             >
          //               <div
          //                 style={{
          //                   textAlign: "center",
          //                   fontSize: "20px",
          //                   fontWeight: "bold",
          //                 }}
          //               >
          //                 <p style={{ margin: 0 }}>
          //                   {/* {resByDB && resByDB.model ? resByDB.model[day] : "Loading..."} */}
          //                   {tableData[day] ? tableData[day] : ""}
          //                 </p>
          //               </div>
          //             </td>
          //           </tr>
          //         ))}
          //       </tbody>
          //     </table>

          //     <div
          //       style={{
          //         paddingTop: "30px",
          //         paddingBottom: "30px",
          //         backgroundColor: "rgb(8,102,255)",
          //         color: "white",
          //         textAlign: "center",
          //       }}
          //     >
          //       <b>OPEN TO CLOSE FREE GAME ZONE</b>
          //     </div>
          //   </div>
          //   <div
          //     className="shadow_card"
          //     style={{ backgroundColor: "rgb(8,101,252)" }}
          //   >
          //     <div style={{ textAlign: "center" }}>
          //       <p style={{ color: "white" }}>
          //         Weekly Panel Or Patti Chart From <b>{startWeekDate}</b> To{" "}
          //         <b>{endWeekDate}</b> For Satyam day,Kolkata Bombay,Satyam
          //         night,Main Bazer
          //       </p>
          //     </div>
          //     <hr
          //       style={{
          //         display: "block",
          //         border: "1px solid black",
          //         height: "0.5px",
          //         width: "100%",
          //         backgroundColor: "black",
          //       }}
          //     />
          //     <div
          //       style={{
          //         backgroundColor: "white",
          //         display: "flex",
          //         justifyContent: "center",
          //         flexDirection: "row",
          //       }}
          //     >
          //       <div className="patti_Table">
          //         {Object.keys(pattiData).map((num) => (
          //           <div
          //             key={num}
          //             style={{
          //               display: "flex",
          //               flexDirection: "row",
          //               margin: "4px 0",
          //             }}
          //           >
          //             <p className="pattiTable_num">
          //               {num} =&gt;
          //             </p>{" "}

          //             <p className="pattiTable_text">
          //               {pattiData[num] ? pattiData[num] : ""}
          //             </p>{" "}

          //           </div>
          //         ))}
          //       </div>
          //     </div>
          //   </div>
          // </div>
          // <>
          //   {data.map((item, index) => {
          //     return (
          //       <div
          //         // className="col-lg-2 col-md-2 col-sm-2 col-xs-2"
          //         key={`guessgame-${item?.guessGameId}-${index}`}
          //       >
          //         {/* <div style={{ justifyContent: 'center', alignItems: 'center', backgroun: '#FFFFFF', height: 40, width: 80, margin: 5, padding: 5, boxShadow:  }}> */}
          //         <div className="single-result-guess-card">
          //           <div key={`guesstable-${index}`} style={{ display: 'flex'}}>
          //             <div style={{ width: '75%'}}>
          //             {item?.gameName && (
          //               <span
          //                 style={{
          //                   fontSize: 16,
          //                   fontWeight: 600,
          //                   paddingRight: 5,
          //                 }}
          //               >
          //                 {item.gameName}:
          //               </span>
          //             )}
          //             <span
          //               style={{
          //                 fontSize: 18,
          //                 fontWeight: 400,
          //                 marginHorizontal: 5,
          //               }}
          //             >
          //               {item.guessNumber} - {item.winnerValue}
          //             </span>
          //             </div>
          //             {isAdminLoggedIn && (
          //               <div style={{ width: '25%' }}>
          //                 <img
          //                   className="img-btn"
          //                   style={{ height: 28, width: 28 }}
          //                   src={editIcon}
          //                   title="Edit game"
          //                   alt="edit-game"
          //                   onClick={() => onEditGame(item.guessGameId)}
          //                 />
          //                 <img
          //                   className="img-btn"
          //                   style={{ height: 28, width: 28 }}
          //                   src={deleteIcon}
          //                   title={`delete game`}
          //                   alt={`delete-game`}
          //                   onClick={() =>
          //                     onDelete({ guessGameId: item.guessGameId })
          //                   }
          //                 />
          //               </div>
          //             )}
          //           </div>
          //         </div>
          //       </div>
          //     );
          //   })}

          // </>
          <>
            <div className="shadow_card">
              {/* <h1>No results available!</h1> */}
              <div style={{ textAlign: "center" }}>
                <h3>Weekly Number Open Or Close From</h3>
                <p>
                  <b>{startWeekDate}</b> To <b>{endWeekDate}</b> For Satyam
                  day,Kolkata Bombay,Satyam night,Main Bazer, Tata Time Bazaar, Kalyan, Bhoothnath
                </p>
              </div>
              <hr
                style={{
                  display: "block",
                  border: "1px solid black",
                  height: "2px",
                  width: "100%",
                  backgroundColor: "black",
                }}
              />
              <table className="firstCard_table">
                <tbody>
                  {Object.keys(tableData).map((day) => (
                    <tr key={day}>
                      <td
                        style={{
                          border: "3px solid black",
                          position: "relative",
                          width: "50%",
                          verticalAlign: "middle",
                        }}
                      >
                        {day}
                      </td>
                      <td
                        style={{
                          border: "3px solid black",
                          position: "relative",
                          verticalAlign: "middle",
                        }}
                      >
                        {tableData[day] === 0 ? (
                          <>
                            {/* <p  onClick={() => handleAdd(day,0)}>+</p>  */}
                            <img
                              className="edit-btn"
                              src={editIcon}
                              title={`edit game`}
                              alt={`edit-game`}
                              onClick={() => handleGuessEdit(day)}
                            />
                          </>
                        ) : (
                          <>
                            {/* <p  onClick={() => handleAdd(day,1)}>+</p> */}
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                gap: "16px",
                                paddingLeft: "10px",
                              }}
                            >
                              <p style={{ margin: 0 }}>{tableData[day]}</p>
                              <img
                                className="edit-btn"
                                src={editIcon}
                                title="edit game"
                                alt="edit-game"
                                onClick={() => handleGuessEdit(day)}
                              />
                            </div>
                          </>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
                <button
                  style={{
                    position: "relative",
                    width: "201%",
                    border: "2px solid rgba(8, 102, 255, 0.8)",
                    background: "white",
                    color: "rgb(8,102,255)",
                  }}
                  onClick={() => saveGuessDataToDB()}
                >
                  <b>Save</b>
                </button>
              </table>

              <div
                style={{
                  paddingTop: "30px",
                  paddingBottom: "30px",
                  backgroundColor: "rgb(8,102,255)",
                  color: "white",
                  textAlign: "center",
                }}
              >
                <b>OPEN TO CLOSE FREE GAME ZONE</b>
              </div>
            </div>
            <div
              className="shadow_card"
              style={{ backgroundColor: "rgb(8,101,252)" }}
            >
              <p style={{ color: "white", textAlign: "center" }}>
                Weekly Panel Or Patti Chart From <b>{startWeekDate}</b> To{" "}
                <b>{endWeekDate}</b> For Satyam day,K Bombay,Satyam
                night,Main Bazer, Tata Time Bazaar, Kalyan, Bhoothnath

              </p>
              <hr
                style={{
                  display: "block",
                  border: "1px solid black",
                  height: "0.5px",
                  width: "100%",
                  backgroundColor: "black",
                }}
              />
              <div
                style={{
                  paddingTop: "30px",
                  paddingBottom: "30px",
                  paddingLeft: "10px",
                  paddingRight: "10x",
                  backgroundColor: "white",
                  display: "block",
                }}
              >
                {Object.keys(pattiData).map((num) => (
                  <p className="guessStyle">
                    {num} =&gt;{" "}
                    {pattiData[num] === 0 ? (
                      <>
                        {/* <div onClick={() => handleGuessAdd(num,0)}>+</div> */}
                        <img
                          className="edit-btn"
                          src={editIcon}
                          title={`edit game`}
                          alt={`edit-game`}
                          onClick={() => handlePattiEdit(num)}
                        />
                      </>
                    ) : (
                      <>
                        {pattiData[num]}
                        {/* <div onClick={() => handleGuessAdd(num,1)} >+</div> */}
                        <img
                          className="edit-btn"
                          src={editIcon}
                          title={`edit game`}
                          alt={`edit-game`}
                          onClick={() => handlePattiEdit(num)}
                        />
                      </>
                    )}
                  </p>
                ))}
                <div style={{ display: "flex" }}>
                  <button
                    style={{
                      position: "relative",
                      marginLeft: "auto",
                      marginRight: "auto",
                      width: "40%",
                      border: "2px solid rgba(8, 102, 255, 0.8)",
                      background: "white",
                      color: "rgb(8,102,255)",
                    }}
                    onClick={() => savePattiTableData()}
                  >
                    <b>Save</b>
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default GuessingTableCard;
