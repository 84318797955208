import React, { useCallback, useEffect, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import MatkaLandingpage from "./components/matkaComponents";
import { createColleciton, getColleciton } from "./services/firebase";
import ErrorPage from "./components/commonComponents/errorPage";
import {
  ADMIN_LOGIN_KEY,
  FIREBASE_COLLECTION_DOC_KEY,
  FIREBASE_COLLECTION_KEY,
  ROUTE_PATHS,
  SUPER_ADMIN_LOGIN_KEY,
} from "./constants";
import AdminPage from "./components/matkaComponents/admin";
import AddGameScreen from "./components/matkaComponents/AddGameScreen";
import AddFatafatResultScreen from "./components/matkaComponents/AddFatafatResultScreen";
import matkaPlayStaticData from "./data/matkaData.json";

import "./components/matkaComponents/css/matkaplay.css";
import PanelChartDetailScreen from "./components/matkaComponents/PanelChartDetailScreen";
import { NavBarHeader } from "./components/matkaComponents/components";
import {
  createGamesForCurrentDay,
  createGamesForTodayAPICall,
  getCurrentDayGames,
  is11AMOrLater,
  removeItemFromLocalStorage,
  getStartandEndofWeek,
} from "./util";
import JodiChartDetailScreen from "./components/matkaComponents/JodiChartDetailScreen";
import HTMLContentScreen from "./components/matkaComponents/HTMLContentScreen";
import FatafatDetailScreen from "./components/matkaComponents/FatafatDetailScreen";
import EditFatafatResultScreen from "./components/matkaComponents/EditFatafatResultScreen";
import AddEditGuessingTableScreen from "./components/matkaComponents/AddEditGuessingTableScreen";
import ApiService from "./services/fetchAPI";
import { API_CONFIGS, BASE_URL_K_BOMBAY } from "./services/fetchAPI/constants";
import ServerDownScreen from "./components/matkaComponents/ServerDownScreen";

export const APIService = new ApiService(BASE_URL_K_BOMBAY);

const { startWeekDate, endWeekDate } = getStartandEndofWeek();

function App() {
  const navigate = useNavigate();

  const [isSuperAdminLoggedIn, setSuperAdminLoggedIn] = useState(false);
  const [isAdminLoggedIn, setAdminLoggedIn] = useState(false);
  const [isLoading, setLoadingState] = useState<boolean>(true);
  const [landingPageData, setLandingPageData] = useState<any>({});
  const [gamesList, setGamesList] = useState<any>([]);
  const [gamesListHistory, setGamesListHistory] = useState<any>([]);
  const [guessingTableList, setGuessingTableList] = useState<any>([]);
  const [fatafatTableList, setFatafatTableList] = useState<any>([]);
  const [gamesTimeTableList, setGamesTimeTableList] = useState<any>([]);
  const [newGuessTableData, setNewGuessTableData] = useState<any>({});
  const [pattiTableData,setPattiTableData]=useState<any>({});

  // let [showChat, setShowChat] = useState(false);

  // const startChat = () => {
  //   setShowChat(true);
  // };
  // const hideChat = () => {
  //   setShowChat(false);
  // };

  

  const startAndEndDate = {
    fromdate: startWeekDate,
    todate: endWeekDate,
  };

  const fetchGuessDataFromDB = () => {
    APIService.post(API_CONFIGS.FETCH_GUESS_DAY.URL, startAndEndDate)
      .then((res: any) => {
        // setResByDB(res.data);
        console.log("Fetched data of Guess Table", res.data);
        if (res.data?.model) {
          const {model} = res.data;
          setNewGuessTableData(model);
        }
        else{
          setNewGuessTableData({ model: null });
        }
      })
      .catch((err) => {
        
        console.log("Error occurred during fetching guess table data", err);
      });
  };
  
  const fetchPattiDataFromDB=()=>{
    APIService.post<any>(API_CONFIGS.FETCH_GAME_PATTI.URL,startAndEndDate)
    .then((res: any)=>{
      console.log("Fetched Patti game data",res.data);
      if(res.data?.model){
        const {model} = res.data;
        setPattiTableData(model)
      }
      else{
        setPattiTableData({})
      }
    })
    .catch((err)=>{
      
      console.log("Error in patti",err);
    })
  }
  

  const fetchGamesList = () => {
    APIService.get<any>(API_CONFIGS.FETCH_CURRENT_DAY_GAMES.URL)
      .then((response) => {
        // console.log("fetchGamesList response", { response });
        if (response?.data?.status) {
          setGamesList(response?.data?.model ?? []);
        }
        // setLoadingState(false);
      })
      .catch((error) => {
        console.error("Error fetching fetchGamesList:", error);
        // setLoadingState(false);
      });
  };
  const fetchGamesListHistory = () => {
    APIService.get<any>(API_CONFIGS.FETCH_GAMES_HISTORY.URL)
      .then((response) => {
        // console.log("fetchGamesListHistory response", { response });
        if (response?.data?.status) {
          setGamesListHistory(response?.data?.model ?? []);
        }
        // setLoadingState(false);
      })
      .catch((error) => {
        console.error("Error fetching fetchGamesListHistory:", error);
        // setLoadingState(false);
      });
  };
  const fetchGuessingTableList = () => {
    APIService.get<any>(API_CONFIGS.FETCH_GUESS_TABLE.URL)
      .then((response) => {
        // console.log("fetchGuessingTableList response", { response });
        if (response?.data?.status) {
          setGuessingTableList(response?.data?.model ?? []);
        }
        // setLoadingState(false);
      })
      .catch((error) => {
        console.error("Error fetchGuessingTableList:", error);
        // setLoadingState(false);
      });
  };

  const fetchFatafatTableList = () => {
    APIService.get<any>(API_CONFIGS.FETCH_FATAFAT.URL)
      .then((response) => {
        // console.log("fetchFatafatTableList response", { response });
        if (response?.data?.status) {
          setFatafatTableList(response?.data?.model ?? []);
        }
        // setLoadingState(false);
      })
      .catch((error) => {
        console.error("Error fetchFatafatTableList:", error);
        // setLoadingState(false);
      });
  };
  const fetchGamesTimeTableList = () => {
    APIService.get<any>(API_CONFIGS.FETCH_GAMES_TIMETABLE.URL)
      .then((response) => {
        // console.log("fetchGuessingTableList response", { response });
        if (response?.data?.status) {
          setGamesTimeTableList(response?.data?.model ?? []);
        }
        // setLoadingState(false);
      })
      .catch((error) => {
        console.error("Error fetchGamesTimeTableList:", error);
        // setLoadingState(false);
      });
  };

  useEffect(() => {
    // -->***dont uncomment or remove this createcollection***<--
    // this will call firebase to create data
    // createColleciton(FIREBASE_COLLECTION_KEY, FIREBASE_COLLECTION_DOC_KEY, matkaPlayStaticData)
    // .then(response => {
    //   console.info( "firebase created successfully!", response);
    //   fetchDataFromFirebase();
    // })
    // .catch(e => {
    //   console.error( "firebase creation failed!", e);
    // });
    // from api fetches
    fetchGamesList();
    fetchGamesListHistory();
    fetchGuessingTableList();
    fetchFatafatTableList();
    fetchGamesTimeTableList();
    //firebase
    fetchDataFromFirebase(); //this will call firebase to get data
    if(startWeekDate&&endWeekDate){
      fetchGuessDataFromDB();
      fetchPattiDataFromDB();
    }
  }, [navigate]);

  // useEffect(() => {
  //   const todaysGames = getCurrentDayGames(gamesList ?? []);

  //   if (todaysGames.length === 0) {
  //     checkAndCreateGames(gamesList ?? []);
  //   }
  // }, [gamesList, gamesTimeTableList])

  const fetchDataFromFirebase = () => {
    getColleciton(FIREBASE_COLLECTION_KEY)
      .then((response) => {
        // console.log("firebase getColleciton response", response);

        if (Array.isArray(response) && response.length > 0) {
          // console.log("firebase getColleciton if**", response[0]);

          checkLoginStatus(response[0].adminAuthUsers);
          setLandingPageData(response[0]);
          const todaysGames = getCurrentDayGames(response[0]?.gameTypes ?? []);
          if (todaysGames.length === 0) {
            // checkAndCreateGames(response[0], true);
          }
        } else {
          // console.log("firebase getColleciton else**", matkaPlayStaticData);
          setLandingPageData(matkaPlayStaticData);
          setAdminLoggedIn(false);
          setSuperAdminLoggedIn(false);
        }

        setLoadingState(false);
      })
      .catch((e) => {
        console.error("firebase getColleciton error", e);
        setLandingPageData(matkaPlayStaticData);
        setLoadingState(false);
      });
  };
  const checkAndCreateGames = (data, isFirebase = false) => {
    // Get the current date in string format (e.g., '2023-06-05')
    const currentDate = new Date().toISOString().split("T")[0];
    // Check if the function has already been executed today
    const hasExecuted = localStorage.getItem("functionExecutedOnDate");
    console.log("checkAndCreateGames starts**", { currentDate, hasExecuted });

    const lastRunTime = localStorage.getItem("lastRunTime");
    // const targetTime = new Date(now);
    // targetTime.setHours(10, 0, 0, 0); // 10 AM or after that everyday
    // Set the time zone to IST (Indian Standard Time, UTC+5:30)
    // const istOffset = 5.5 * 60 * 60 * 1000;
    // const currentIstTime = new Date(now.getTime() + istOffset);

    // Extract hours and minutes from the IST time
    // const hours = currentIstTime.getUTCHours();
    // if (!lastRunTime || new Date(lastRunTime) < targetTime) {
    if (!lastRunTime || is11AMOrLater()) {
      // create games for the day
      isFirebase
        ? createGamesForCurrentDay(data, onCreateCallback)
        : createGamesForTodayAPICall(gamesTimeTableList, onCreateCallback);
    }
  };

  const onCreateCallback = useCallback(() => {
    // Update the lastRunTime in local storage to prevent running the function again today
    localStorage.setItem("lastRunTime", new Date().toISOString());
    fetchGamesList();
    // window.location.reload();
  }, []);
  const checkLoginStatus = useCallback((authData) => {
    const adminCredentials = localStorage.getItem(ADMIN_LOGIN_KEY);
    const parsedAdminCredentials =
      adminCredentials !== null ? JSON.parse(adminCredentials) : null;
    const superAdminCredentials = localStorage.getItem(SUPER_ADMIN_LOGIN_KEY);
    const parsedSuperAdminCredentials =
      superAdminCredentials !== null ? JSON.parse(superAdminCredentials) : null;

    if (
      parsedAdminCredentials &&
      authData[0].userName === parsedAdminCredentials.userName &&
      authData[0].password === parsedAdminCredentials.password
    ) {
      setAdminLoggedIn(true);
    }
    // else if (authData.length > 1 && authData[1].userName === parsedSuperAdminCredentials.userName && authData[1].password === parsedSuperAdminCredentials.password) {
    //   setSuperAdminLoggedIn(true);
    // }
  }, []);

  const onAdminLoginSuccess = useCallback(
    (isSuperAdmin) => {
      navigate(ROUTE_PATHS.HOME);
      isSuperAdmin ? setSuperAdminLoggedIn(true) : setAdminLoggedIn(true);
    },
    [navigate]
  );

  const handleLogout = useCallback((e) => {
    e.preventDefault();
    window.location.reload();
    removeItemFromLocalStorage(ADMIN_LOGIN_KEY);
  }, []);
  // console.log("adfadfasd", landingPageData?.serverInfo?.isServerDown);
  return (
    <>
      {isLoading ? (
        <div className="container">
          <h1>Loading data...</h1>
        </div>
      ) : (
        <>
          <NavBarHeader
            isAdminLoggedIn={isAdminLoggedIn}
            handleLogout={handleLogout}
            isServerDown={landingPageData.serverInfo.isServerDown}
          />
          <div style={{ marginTop: 60 }}>
            <Routes>
              <Route
                path={ROUTE_PATHS.HOME}
                errorElement={<ErrorPage />}
                element={
                  <>
                    <Helmet>
                      {landingPageData?.metaData?.homePage &&
                        landingPageData?.metaData?.homePage.map(
                          (item, index) => {
                            if (item.tagName === "title") {
                              return (
                                <title key={`${item}-${index}`}>
                                  {item.tagValue}
                                </title>
                              );
                            }
                            return (
                              <meta
                                key={`${item}-${index}`}
                                name={item.tagName}
                                content={item.tagValue}
                              />
                            );
                          }
                        )}
                      <link
                        rel="canonical"
                        href="https://www.tatamatkaplay.com"
                      />
                    </Helmet>
                    {!landingPageData.serverInfo.isServerDown ? (
                      <MatkaLandingpage
                        matkaPlayData={landingPageData}
                        adminLoggedInSuccess={isAdminLoggedIn}
                        gamesList={gamesList}
                        gamesListHistory={gamesListHistory}
                        guessingTableList={guessingTableList}
                        fatafatTableList={fatafatTableList}
                        gamesTimeTableList={gamesTimeTableList}
                        newGuessTableData={newGuessTableData}
                        pattiTableData={pattiTableData}
                      />
                    ) : (
                      <ServerDownScreen />
                    )}
                  </>
                }
              />
              <Route
                path={ROUTE_PATHS.ADMIN_LOGIN}
                errorElement={<ErrorPage />}
                element={
                  <>
                    <Helmet>
                      {landingPageData?.metaData?.contactusPage &&
                        landingPageData?.metaData?.contactusPage.map((item) => {
                          if (item.tagName === "title") {
                            return <title>{item.tagValue}</title>;
                          }
                          return (
                            <meta name={item.tagName} content={item.tagValue} />
                          );
                        })}
                      <link
                        rel="canonical"
                        href="https://www.kbombaysattamatka.com/contact-us"
                      />
                    </Helmet>
                    <AdminPage
                      data={landingPageData["adminAuthUsers"]}
                      onAdminLoginSuccess={onAdminLoginSuccess}
                    />
                  </>
                }
              />

              <Route
                path={`${ROUTE_PATHS.ADD_GAME}/:gameIndex?`}
                errorElement={<ErrorPage />}
                element={
                  <>
                    <Helmet>
                      {landingPageData?.metaData?.contactusPage &&
                        landingPageData?.metaData?.contactusPage.map((item) => {
                          if (item.tagName === "title") {
                            return <title>{item.tagValue}</title>;
                          }
                          return (
                            <meta name={item.tagName} content={item.tagValue} />
                          );
                        })}
                      <link
                        rel="canonical"
                        href="https://www.kbombaysattamatka.com/contact-us"
                      />
                    </Helmet>
                    <AddGameScreen
                      data={landingPageData}
                      gamesList={gamesList}
                      guessingTableList={guessingTableList}
                      fatafatTableList={fatafatTableList}
                      gamesTimeTableList={gamesTimeTableList}
                    />
                  </>
                }
              />

              <Route
                path={`${ROUTE_PATHS.ADD_FATAFAT_RESULT}/:fatafatGuessId?`}
                errorElement={<ErrorPage />}
                element={
                  <>
                    <Helmet>
                      {landingPageData?.metaData?.contactusPage &&
                        landingPageData?.metaData?.contactusPage.map((item) => {
                          if (item.tagName === "title") {
                            return <title>{item.tagValue}</title>;
                          }
                          return (
                            <meta name={item.tagName} content={item.tagValue} />
                          );
                        })}
                      <link
                        rel="canonical"
                        href="https://www.kbombaysattamatka.com/contact-us"
                      />
                    </Helmet>
                    <AddFatafatResultScreen
                      data={landingPageData}
                      fatafatTableList={fatafatTableList}
                    />
                  </>
                }
              />
              <Route
                path={`${ROUTE_PATHS.FATAFAT_RESULT_DETAIL}`}
                errorElement={<ErrorPage />}
                element={
                  <>
                    <Helmet>
                      {landingPageData?.metaData?.contactusPage &&
                        landingPageData?.metaData?.contactusPage.map((item) => {
                          if (item.tagName === "title") {
                            return <title>{item.tagValue}</title>;
                          }
                          return (
                            <meta name={item.tagName} content={item.tagValue} />
                          );
                        })}
                      <link
                        rel="canonical"
                        href="https://www.kbombaysattamatka.com/contact-us"
                      />
                    </Helmet>
                    <FatafatDetailScreen
                      data={landingPageData}
                      fatafatTableList={fatafatTableList}
                    />
                  </>
                }
              />
              <Route
                path={`${ROUTE_PATHS.EDIT_FATAFAT_RESULT_DETAIL}/:fatafatGuessId?`}
                errorElement={<ErrorPage />}
                element={
                  <>
                    <Helmet>
                      {landingPageData?.metaData?.contactusPage &&
                        landingPageData?.metaData?.contactusPage.map((item) => {
                          if (item.tagName === "title") {
                            return <title>{item.tagValue}</title>;
                          }
                          return (
                            <meta name={item.tagName} content={item.tagValue} />
                          );
                        })}
                      <link
                        rel="canonical"
                        href="https://www.kbombaysattamatka.com/contact-us"
                      />
                    </Helmet>
                    <EditFatafatResultScreen
                      data={landingPageData}
                      fatafatTableList={fatafatTableList}
                    />
                  </>
                }
              />

              <Route
                path={`${ROUTE_PATHS.ADD_EDIT_GUESSING_TABLE_RESULT}/:guessGameId?`}
                errorElement={<ErrorPage />}
                element={
                  <>
                    <Helmet>
                      {landingPageData?.metaData?.contactusPage &&
                        landingPageData?.metaData?.contactusPage.map((item) => {
                          if (item.tagName === "title") {
                            return <title>{item.tagValue}</title>;
                          }
                          return (
                            <meta name={item.tagName} content={item.tagValue} />
                          );
                        })}
                      <link
                        rel="canonical"
                        href="https://www.kbombaysattamatka.com/contact-us"
                      />
                    </Helmet>
                    <AddEditGuessingTableScreen
                      data={landingPageData}
                      guessingTableList={guessingTableList}
                    />
                  </>
                }
              />

              <Route
                path={`${ROUTE_PATHS.PANEL_CHARTS_DETAIL}/:gameName`}
                errorElement={<ErrorPage />}
                element={
                  <>
                    <Helmet>
                      {landingPageData?.metaData?.contactusPage &&
                        landingPageData?.metaData?.contactusPage.map((item) => {
                          if (item.tagName === "title") {
                            return <title>{item.tagValue}</title>;
                          }
                          return (
                            <meta name={item.tagName} content={item.tagValue} />
                          );
                        })}
                      <link
                        rel="canonical"
                        href="https://www.kbombaysattamatka.com/contact-us"
                      />
                    </Helmet>
                    <PanelChartDetailScreen
                      data={gamesListHistory ?? landingPageData.gameTypes}
                    />
                  </>
                }
              />
              <Route
                path={`${ROUTE_PATHS.JDOI_CHARTS_DETAIL}/:gameName`}
                errorElement={<ErrorPage />}
                element={
                  <>
                    <Helmet>
                      {landingPageData?.metaData?.contactusPage &&
                        landingPageData?.metaData?.contactusPage.map((item) => {
                          if (item.tagName === "title") {
                            return <title>{item.tagValue}</title>;
                          }
                          return (
                            <meta name={item.tagName} content={item.tagValue} />
                          );
                        })}
                      <link
                        rel="canonical"
                        href="https://www.kbombaysattamatka.com/contact-us"
                      />
                    </Helmet>
                    <JodiChartDetailScreen
                      data={gamesListHistory ?? landingPageData.gameTypes}
                    />
                  </>
                }
              />

              <Route
                path={`${ROUTE_PATHS.HTML_CONTENT_SCREEN}/:hrefVal`}
                errorElement={<ErrorPage />}
                element={
                  <>
                    <Helmet>
                      {landingPageData?.metaData?.contactusPage &&
                        landingPageData?.metaData?.contactusPage.map((item) => {
                          if (item.tagName === "title") {
                            return <title>{item.tagValue}</title>;
                          }
                          return (
                            <meta name={item.tagName} content={item.tagValue} />
                          );
                        })}
                      <link
                        rel="canonical"
                        href="https://www.kbombaysattamatka.com/contact-us"
                      />
                    </Helmet>
                    <HTMLContentScreen
                      data={gamesListHistory ?? landingPageData.gameTypes}
                    />
                  </>
                }
              />

              <Route
                path={`${ROUTE_PATHS.SERVER_DOWN_SCREEN}`}
                errorElement={<ErrorPage />}
                element={
                  <>
                    <Helmet>
                      {landingPageData?.metaData?.contactusPage &&
                        landingPageData?.metaData?.contactusPage.map((item) => {
                          if (item.tagName === "title") {
                            return <title>{item.tagValue}</title>;
                          }
                          return (
                            <meta name={item.tagName} content={item.tagValue} />
                          );
                        })}
                      <link
                        rel="canonical"
                        href="https://www.kbombaysattamatka.com/contact-us"
                      />
                    </Helmet>
                    <ServerDownScreen />
                  </>
                }
              />
            </Routes>
            {/* <Footer data={landingPageData["contact-us"]} /> */}
          </div>
        </>
      )}
    </>
  );
}

export default App;
